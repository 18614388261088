.container {
  border: 1px solid #0000000d;
}

.logo {
  height: 2rem;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.logo img.ant-image-img {
  height: 2rem;
  max-width: 14rem;
  width: auto;
  object-fit: contain;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
