.ant-layout-header {
  padding: 0;
}

.ant-table-column-sorter .anticon {
  color: white !important;
}
.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: currentColor !important;
}

.ant-steps-item-active .ant-steps-icon-dot {
  left: 0px !important;
}

.label {
  font-size: 12px;
  color: #8c8c8c !important;
}

.block-text {
  font-size: 15px;
  margin: 0px;
  overflow-wrap: anywhere;
}

.ant-menu-item-selected {
  background-color: #c2e2f3 !important;
}

.ant-table-thead > tr > th {
  font-size: 14px;
}

.ineligible .ant-table-thead > tr > th {
  background-color: #9e9e9e;
  font-size: 14px;
}

.ant-table-title {
  padding: 12px 0px !important;
}

.site-card-wrapper {
  padding: 20px;
  background: #ececec;
}

.filepond--root .filepond--credits {
  display: none;
}
