.CSVImporter_ColumnDragTargetArea {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  height: 140px;
  background-color: #335771;
}

.CSVImporter_ColumnDragTargetArea section {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  max-width: 100%;
}

.CSVImporter_ColumnDragCard__cardValue {
  border-style: solid solid solid solid;
  border-width: thin;
}

.CSVImporter_ColumnDragTargetArea__box
  > .CSVImporter_ColumnDragTargetArea__boxValue
  > .CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp {
  border-style: none !important;
}

.CSVImporter_ImporterFrame {
  background-color: #335771;
  color: white;
}

.CSVImporter_ColumnDragSourceArea {
  background-color: #335771;
  padding: 0%;
}

.CSVImporter_ColumnDragSourceArea__pageIndicator {
  color: white;
}

.CSVImporter_ColumnDragSourceArea__page {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  height: auto;
}

.CSVImporter_ColumnDragSourceArea__box {
  flex: 0 0 auto;
  width: auto;
  height: 150px;
  max-width: 100%;
}

.CSVImporter_ColumnDragSourceArea__control {
  display: none;
}

.CSVImporter_ImporterFrame__footerError {
  color: yellow;
}

.CSVImporter_ImporterFrame__headerTitle {
  color: white;
}

.CSVImporter_FormatPreview__header {
  color: white;
}

.CSVImporter_FormatPreview__headerToggle {
  color: white;
}

.CSVImporter_ImporterFrame__headerSubtitle {
  color: white;
}

.CSVImporter_ColumnDragTargetArea__boxLabel {
  color: white;
}

.CSVImporter_ColumnDragCard {
  color: black;
}

.CSVImporter_ProgressDisplay__progressBar {
  color: lightgreen;
}

.CSVImporter_ProgressDisplay__count {
  color: white;
}
.CSVImporter_ProgressDisplay__progressBarIndicator {
  background-color: yellow !important;
}

.CSVImporter_ProgressDisplay__count {
  color: yellow;
}

.CSVImporter_ProgressDisplay__status {
  color: yellow;
}

.CSVImporter_ColumnDragSourceArea__pageFiller {
  display: none;
}

.CSVImporter_IconButton {
  filter: invert(100%);
}

.CSVImporter_FileStep__header {
  color: white;
}
.CSVImporter_FileStep__headerToggle {
  color: white;
}

.CSVImporter_ColumnDragSourceArea__pageIndicator {
  top: -0.3em;
}
.CSVImporter_ColumnDragSourceArea__page {
  padding-top: 1.5em;
}

.confirmation-card {
  margin-top: 20px;
}
.table-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
