.mobile-preview-container {
  display: flex;
  justify-content: center;
  gap: 48px;
}

.preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: #1f2937;
}

.iphone-frame {
  position: relative;
  width: 375px;
  height: 760px;
  background: #1f1f1f;
  border-radius: 55px;
  padding: 12px;
  box-shadow:
    0 50px 100px -20px rgba(0, 0, 0, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(255, 255, 255, 0.1);
}

.iphone-frame::before,
.iphone-frame::after {
  content: '';
  position: absolute;
  background: #444;
  border-radius: 2px;
}

.iphone-frame::before {
  left: -2px;
  top: 100px;
  width: 3px;
  height: 30px;
  border-radius: 4px 0 0 4px;
}

.iphone-frame::after {
  right: -2px;
  top: 120px;
  width: 3px;
  height: 50px;
  border-radius: 0 4px 4px 0;
}

.iphone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 32px;
  background: #1f1f1f;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 20;
}

.iphone-speaker {
  width: 40px;
  height: 4px;
  background: #333;
  border-radius: 4px;
}

.iphone-camera {
  width: 8px;
  height: 8px;
  background: #333;
  border-radius: 50%;
}

.iphone-screen {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 45px;
  overflow: hidden;
  position: relative;
}

.status-bar {
  height: 44px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}

.time {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.signal,
.wifi,
.battery {
  width: 16px;
  height: 16px;
  background: white;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.signal {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'/%3E%3C/svg%3E");
}

.wifi {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9zm8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0zm-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z'/%3E%3C/svg%3E");
}

.battery {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4zM11 20v-5.5H9L13 7v5.5h2L11 20z'/%3E%3C/svg%3E");
}

/* Login Preview Styles */
.login-preview {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 24px 0;
}

.logo-container {
  width: 192px;
  height: 48px;
  margin-bottom: 128px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.welcome-text {
  text-align: center;
}

.welcome-text h1,
.welcome-text h2 {
  color: white;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 8px;
}

.welcome-text p {
  color: #d1d5db;
  font-size: 14px;
  margin-bottom: 4px;
}

.login-buttons {
  padding: 0 24px 48px;
  border-radius: 15px;
}

.contained {
}

.outlined {
  background-color: transparent;
}

.button {
  width: 100%;
  padding: 18px;
  margin-bottom: 16px;
  border-radius: 20px;
}

.terms-text {
  text-align: center;
  color: #d1d5db;
  font-size: 12px;
}

/* Main Page Preview Styles */
.main-preview {
  height: 100%;
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0px 18px;
}

.header h1 {
  color: white;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}

.header p {
  color: #d1d5db;
  font-size: 14px;
}

.main-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
  margin-bottom: 80px;

  overflow-x: hidden;
  /* //hide scroll  */
  scrollbar-width: none;
}

.balance-card {
  padding: 6px 36px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0px;
}

.section-title span {
  color: #4b5563;
}

.balance-info {
  text-align: center;
}

.balance-info p {
  color: #6b7280;
  font-size: 14px;
}

.balance-info h2 {
  color: #1f2937;
  font-size: 30px;
  font-weight: 700;
}

.advance-button {
  width: 100%;
  border-radius: 15px;
}

.how-it-works {
  text-align: center;
  font-size: 12px;
  text-decoration: underline;
}

.how-it-works span {
  padding: 0 4px;
}

.financial-tools h3 {
  color: #4b5563;
  font-size: 16px;
  margin-bottom: 12px;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: max-content;
  gap: 8px;
}

.tool-card {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 10px;
  max-width: 120px;
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.tool-icon {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tool-card p {
  color: #4b5563;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.featured-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: max-content;
  gap: 12px;
  margin-bottom: 60px;
}

.featured-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  max-width: 200px;
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.tab-navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.nav-item span {
  font-size: 12px;
  margin-top: 4px;
}

.home-icon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
}

.home-logo {
  width: 28px;
  height: 28px;
  object-fit: contain;
}
